<template>
  <base-wrapper :loading="loadingJobStatus">
    <base-card title="Export Mitra Usaha ND dan NE">
      <div class="space-y-4">
        <div>
          <base-button :loading="loading" @click="onExport">
            <p v-if="loading">{{ loadingText }}</p>
            <template v-else>
              <Icon icon="heroicons:document-text-solid" class="h-4 w-4" />
              Export
            </template>
          </base-button>
        </div>

        <alert v-if="success.visible" @dismiss="success.visible = false">
          Export berhasil, klik
          <a :href="success.url" target="_blank" class="font-bold underline"
            >download</a
          >
          untuk melihat file hasil export.
        </alert>
      </div>
    </base-card>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions, mapGetters } from 'vuex';
import Alert from '@/components/base/Alert.vue';

export default {
  mixins: [requestMixin],
  components: { Alert },
  data() {
    return {
      channelName: null,
      loading: false,
      loadingText: null,
      loadingJobStatus: true,
      success: {
        visible: false,
        url: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    addListener() {
      this.channelName = `private-App.Models.User.${this.me.id}`;

      const userChannel = this.$pusher.subscribe(this.channelName);

      userChannel.bind(
        'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
        (e) => {
          if (e.type === 'App\\Notifications\\MitraUsahaNdNeExported') {
            if (e.status === 'failed') {
              this.stopLoadingExport();

              this.createAlert({
                data: 'Gagal mengexport mitra usaha nd dan ne',
                status: 'error',
              });
            } else if (e.status === 'success') {
              this.stopLoadingExport();

              this.success.visible = true;
              this.success.url = e.file_url;
            }
          }
        }
      );
    },
    async loadJobStatus() {
      this.loadingJobStatus = true;

      const [res, err] = await this.request(
        '/api/v1/users/-actions/check-job-status',
        {
          params: {
            type: 'export_mitra_usaha_nd_ne',
          },
        }
      );

      if (!err) {
        const { running } = res;

        if (running) {
          this.startLoadingExport('Sedang Diproses');
        }
      }

      this.loadingJobStatus = false;
    },
    removeListener() {
      this.$pusher.unsubscribe(this.channelName);
    },
    async onExport() {
      this.startLoadingExport('Mengirim Permintaan');

      const [, err] = await this.request(
        '/api/v1/offices/-actions/export-mitra-usaha-nd-ne',
        {
          method: 'post',
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });

        this.stopLoadingExport();
      } else {
        this.startLoadingExport('Sedang Diproses');
      }
    },
    startLoadingExport(text) {
      this.loadingText = text;
      this.loading = true;
    },
    stopLoadingExport() {
      this.loadingText = null;
      this.loading = false;
    },
  },
  created() {
    this.loadJobStatus();
    this.addListener();
  },
  beforeRouteLeave(to, from, next) {
    this.removeListener();
    next();
  },
};
</script>
